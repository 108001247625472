@import "./slick.css";
@import "./section.css";
@tailwind base;
@tailwind components;

body {
  @apply w-screen;
  @apply text-gray-800;
  margin: 0;
  overflow-x: hidden;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}
.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}
.heading{
  @apply text-2xl md:text-4xl;
}
.buttonProgress {
  color: #00BF1B;
  position: absolute;
  top: 50%;
  z-Index:2;
  left: 50%;
  margin-top: -12px;
  margin-left: -21px;
}
.nprogress{
  position: fixed!important;
  top: 0;
  width: 100%;
  z-index: 100;
}

[contenteditable=true]:empty:before{
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  color:#6c757d;
}


@tailwind utilities;

@layer utilities {
  .body-p-x{
    @apply container;
  }
  @variants responsive {  
    .masonry-3-col {
      column-count: 3;
      column-gap: 1em;
    }
    .masonry-2-col {
      column-count: 2;
      column-gap: 1em;
    }
    .break-inside {
      break-inside: avoid;
    }
  }
}
